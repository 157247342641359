<template>
  <CaixaquiMenu :items="menus"></CaixaquiMenu>
</template>

<script>
import CaixaquiMenu from "../../../components/caixaqui/CaixaquiMenu";
import ROUTERS from "../../../routes/router-constants";
import { mapGetters } from "vuex";

export default {
  name: "CaixaquiMenuNegocios",
  components: {
    CaixaquiMenu
  },
  computed: {
    ...mapGetters("controller", ["sourceRoute"]),
    menus() {
      return [
        { text: "Pesquisar Clientes", to: "" },
        { text: "Cadastro de Clientes", to: { name: ROUTERS.caixaqui.cadastroClientes.cpfCliente.name } },
        { text: "Avaliação de Risco", to: { name: ROUTERS.caixaqui.avaliacaoRiscos.dadosTomador.name } },
        { text: "Crédito Imobiliário", to: "" },
        { text: "Abertura de Contas", to: "" },
        { text: "Cheque Azul", to: "" },
        { text: "Cartão de Crédito", to: "" },
        { text: "CDC - Crédito Direto Caixa", to: "" },
        { text: "Crédito Consignado", to: "" },
        { text: "Caixa Seguradora", to: "" },
        { text: "Formulários Enviados", to: { name: ROUTERS.caixaqui.formulariosEnviados.name } },
        { text: "Digitalizar Documentos", to: "" },
        { text: "Voltar", to: { name: ROUTERS.caixaqui.menus.servicoCliente.name } },
        { text: "Sair", to: this.sourceRoute, isOrange: true }
      ];
    }
  }
};
</script>

<style scoped></style>
